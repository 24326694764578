<template>
  <div>
    <pcHeaders :value="queryParams.codeNo"
               @updateParentValue="handleUpdate"></pcHeaders>

    <div class="banner">
      <div class="section">
        <div class="hen">
          <div style="margin-right: 0;font-size: 14px;height: 25px;
        line-height: 25px;width: 42px;">类别：</div>
          <div style="display: flex;flex-wrap: wrap;width: 1150px;">
            
            <div :class="tab1 == index ? 'selet1 seletActive1' : 'selet1'"
                 v-for="(item,index) in categoryList"
                 :key="item.ID"
                 :title="item.CODE_NAME"
                 @click="categoryHandel(item,index)">{{ item.CODE_NAME }}</div>
          </div>
        </div>

        <div class="hen"
             style="padding-top: 20px;">
          <div style="margin-right: 0;font-size: 14px;height: 25px;
        line-height: 25px;width: 42px;">行业：</div>
          <div style="display: flex;flex-wrap: wrap;width: 1150px;">
            <div :class="tab2 == index ? 'selet seletActive' : 'selet'"
                 v-for="(item,index) in industryList"
                 :title="item.CODE_NAME"
                 :key="item.ID"
                 @click="industryHandel(item,index)">{{ item.CODE_NAME }}</div>
          </div>
        </div>

        <div class="hen"
             style="padding-top: 20px;">
          <div style="margin-right: 0;font-size: 14px;height: 25px;
        line-height: 25px;width: 42px;">专业：</div>

          <div style="display: flex;flex-wrap: wrap;width: 1150px;">
            <div :class="tab3 == index ? 'selet seletActive' : 'selet'"
                 v-for="(item,index) in specialityList"
                 :title="item.CODE_NAME"
                 :key="item.ID"
                 @click="specialityHandel(item,index)">{{ item.CODE_NAME }}</div>
          </div>

        </div>
      </div>
    </div>
    <div class="banner"
         style="background: #F7F7F7;">
      <div class="listBox"
           v-if="classList[0] !== ''">
        <div class="forBox"
             v-for="item in classList"
             :key="item.ID">
          <div class="imgBox">
            <img v-if="item.CLASS_IMAGE && item.CLASS_IMAGE !==null && item.CLASS_IMAGE !== ''"
                 style="width: 100%;height: 100%;"
                 :src="item.CLASS_IMAGE"
                 @click="classDetail(item)"
                 alt=""
                 @error="handleError">
            <div class="imgBox"
                 v-else
                 @click="classDetail(item)"
                 style="position: relative;display: flex;justify-content: center;align-items: center;">
              <img style="position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;"
                   src="../../assets/indexImg/noimg.png"
                   alt="">
              <div style="font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;position: relative;z-index: 2;">
                {{  item.CLASS_NAME }}
              </div>
            </div>

          </div>

          <div class="centerBox">
            <div class="top"
                 @click="classDetail(item)">
              {{ item.CLASS_NAME }}
            </div>
            <div class="section-rgt-1">
              <span> {{ item.lx }}</span>
              <span>{{ item.CLASS_CREDITHOUR }}学时</span>
              <span style="color: #2878ff;">{{ item.CLASS_TYPE == '01' ? '在线学习' : item.CLASS_TYPE == '02' ? '面授' : '混合' }}</span>
            </div>
            <div title=""
                 class="section-rgt-2">
              班级介绍：{{ item.CLASS_DESC }}
            </div>

            <div title=""
                 class="section-rgt-2">
              报名须知：{{ item.CLASS_DEMO }}
            </div>

            <div class="botom">
              <div class="xs"
                   v-if="item.ISCLASSAPPLY == '01' || item.ISCLASSAPPLY == '' ||item.ISCLASSAPPLY == null">
                <span>¥</span>
                {{ item.CREDIT_PRICE }}/学时
              </div>
              <div class="xs"
                   v-else>
                <span>¥</span>
                {{ item.CLASS_PRICE.toFixed(1) }}
              </div>
              <div style="display: flex;">
                <div class="btn"
                     @click="purchase(item)"
                     v-if="item.CLASS_ENROL_STATUS == '02' && item.timeFlag == '02'">
                  <img src="../../assets/indexImg/icon_cart_list.png"
                       alt="">
                  购买
                </div>
                <div class="btn"
                     style="color: #FF8000;border-color: #FF8000;"
                     @click="tableHandel(item)">
                  <img src="../../assets/indexImg/icon_listen.png"
                       alt="">
                  试听
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="forBox"
             style="display: flex;justify-content: center;align-items: center;font-size: 18px;"
             v-if="classList.length == 0">
          暂无数据~
        </div>
        <pagination v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize"
                    @pagination="getClassList" />
      </div>

    </div>

    <pcBottom></pcBottom>
    <flotRight/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { getProjectCodeList, getClassList } from '@/api/lsitFs.js';
import { doShoppingCartZG } from '@/api/car.js';
import flotRight from "@/views/component/flotRight.vue";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    flotRight
  },
  data() {
    // 这里存放数据
    return {
      categoryList: [], //类别
      industryList: [], //行业
      specialityList: [], //专业
      tab1: 0, //选中
      tab2: 0,
      tab3: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      classList: [''],
      codeNo: null, //跳转过来的code
      fCodeNo: null,
      defaultImageUrl: require('../../assets/images/noimg.png'),
      carFlag:true,//购物防止重复点击
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    '$route.query.codeNo': function (newVal) {
      console.log('URL参数已经更新', newVal);
      this.codeNo = newVal;
      this.getList();
    },
    '$route.query.fCodeNo': function (newVal) {
      console.log('URL参数已经更新', newVal);
      this.fCodeNo = newVal;
    },
    '$route.query.className': function (newVal) {
      console.log('URL参数已经更新', newVal);
      this.queryParams.className = newVal;
      if (this.queryParams.className !== '') {
        this.tab1 = null;
        this.tab2 = null;
        this.tab3 = null;
        this.queryParams.codeNo = '';
      } else {
        this.tab1 = 0;
        this.tab2 = 0;
      }
      this.getClassList();
    }
  },
  // 方法集合
  methods: {
    handleUpdate(newValue) {
      console.log('newValue', newValue);
      this.queryParams.codeNo = newValue.localValue; // 处理子组件传递过来的新值
      this.queryParams.className = newValue.className; // 处理子组件传递过来的新值
      this.queryParams.pageNum = 1; // 处理子组件传递过来的新值
      this.queryParams.pageSize = 10; // 处理子组件传递过来的新值
      this.tab1 = null;
      this.tab2 = null;
      this.tab3 = null;
      this.getClassList();
    },
    //公需课
    toGx() {
      this.$router.push('/gx');
    },
    //试听
    tableHandel(row) {
      this.$router.push({
        path: '/classDetail',
        query: {
          classId: row.ID
        }
      });
    },
    purchase(item) {
      console.log(item);

      if (item.ISCLASSAPPLY == '02') {
        if (this.carFlag) {
          this.carFlag = false
        //整体报名直接加入购物车
        doShoppingCartZG({ classId: item.ID }).then(res => {
          if (res.data.data.code == '1') {
            this.$message({
              message: '添加购物车成功！',
              type: 'success'
            });
            this.$router.push('/car');
            this.carFlag = true
          } else {
            this.$message({
              message: res.data.data.msg,
              type: 'warning'
            });
            this.carFlag = true
          }
        });
      }
        return false;
      }
      //非整体报名
      this.$router.push({
        path: '/purchase',
        query: {
          id: item.ID
        }
      });
    },
    //类别点击
    categoryHandel(item, index) {
      console.log(item, index);
      this.tab1 = index;
      this.queryParams.codeNo = item.CODE_NO;
      this.queryParams.pageNum = 1; //点击行业重置页数
      this.getClassList();
      this.tab2 = null;
      this.tab3 = null;
      this.industryList = item.children;
      this.specialityList = []
    },
    //行业点击
    industryHandel(item, index) {
      this.tab2 = index;
      this.tab3 = null;
      this.queryParams.codeNo = item.CODE_NO;
      this.queryParams.pageNum = 1; //点击行业重置页数
      this.getClassList();
      this.specialityList = item.children;
    },
    //专业点击
    specialityHandel(item, index) {
      this.tab3 = index;
      this.queryParams.pageNum = 1; //点击行业重置页数
      this.queryParams.codeNo = item.CODE_NO;
      this.getClassList();
    },
    //获取班级列表
    getClassList() {
      getClassList(this.queryParams).then(res => {
        console.log(res);
        this.classList = res.data.rows;
        this.total = res.data.total;
      });
    },
    //班级详情
    classDetail(item) {
      this.$router.push({
        path: '/classDetail',
        query: {
          classId: item.ID
        }
      });
    },
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    getList() {
      getProjectCodeList().then(res => {
        this.categoryList = res.data.data;
        console.log(this.categoryList, 'this.categoryList');
        let data = {
          CODE_NO: this.fCodeNo
        };
        if (this.codeNo == null && this.fCodeNo !== null) {
          this.categoryList.forEach((v, idx) => {
            if (v.CODE_NO == this.fCodeNo) {
              this.$nextTick(() => {
                this.tab1 = idx;
              });
              data.children = v.children;
            }
          });

          this.categoryHandel(data, '');
          return false;
        }

        if (this.codeNo !== null && this.fCodeNo !== null) {
          this.queryParams.codeNo = this.codeNo;
          this.categoryList.forEach((v, idx) => {
            if (v.CODE_NO == this.fCodeNo) {
              this.$nextTick(() => {
                this.tab1 = idx;
              });
              this.industryList = v.children;
              v.children.forEach((x, index) => {
                if (x.CODE_NO == this.codeNo) {
                  this.$nextTick(() => {
                    this.tab2 = index;
                  });
                }
              });
            }
          });
          let data = {
            CODE_NO: this.codeNo
          };
          this.industryHandel(data, 0);
          console.log(data, 'this.fCodeNo');
        } else {
          this.queryParams.codeNo = this.categoryList[0].children[0].CODE_NO;
          this.industryList = this.categoryList[0].children;
          this.industryHandel(this.categoryList[0].children[0], 0);
        }
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.codeNo || this.$route.query.fCodeNo) {
      this.codeNo = this.$route.query.codeNo;
      this.fCodeNo = this.$route.query.fCodeNo;
    }
    if (this.$route.query.className && this.$route.query.className !== '') {
      this.queryParams.className = this.$route.query.className;
      this.getClassList();
      getProjectCodeList().then(res => {
        this.categoryList = res.data.data;
        this.tab1 = null;
        this.tab2 = null;
        this.tab3 = null;
        this.industryList = this.categoryList[0].children;
      });
    } else {
      this.queryParams.className = '';
      this.getList();
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.banner {
  font-family: Regular;
  display: flex;
  justify-content: center;
  .listBox {
    width: 1200px;
    padding-top: 20px;
    .forBox {
      width: 1200px;
      height: 225px;
      background: #ffffff;
      opacity: 1;
      border-radius: 6px;
      margin-bottom: 20px;
      padding: 20px 30px 20px 20px;
      display: flex;
      .imgBox {
        width: 230px;
        height: 185px;
        margin-right: 20px;
      }
      .centerBox {
        .top {
          font-size: 24px;
          font-family: bold;
          color: #333333;
          width: 900px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 10px;
          cursor: pointer;
        }
        .section-rgt-1 {
          width: 900px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 10px;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          font-family: Regular;
          font-weight: 500;
          color: #666666;
          span:first-child {
            padding: 0 12px;
            height: 24px;
            line-height: 24px;
            background: rgba(40, 120, 255, 0.1);
            border-radius: 2px;
            font-size: 12px;
            font-family: Regular;
            font-weight: 500;
            color: #2878ff;
            margin-right: 10px;
            display: inline-block;
          }
          span:nth-child(2) {
            padding: 0 12px;
            display: inline-block;
            height: 24px;
            line-height: 24px;
            background: rgba(255, 128, 0, 0.1);
            border-radius: 2px;
            font-size: 12px;
            font-family: Regular;
            font-weight: 500;
            color: #ff8000;
            margin-right: 20px;
          }
        }
        .section-rgt-2 {
          width: 900px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-family: Regular;
          font-weight: 400;
          color: #999999;
          margin-top: 8px;
        }

        .botom {
          display: flex;
          justify-content: space-between;
          height: 40px;
          line-height: 40px;
          margin-top: 12px;
          .xs {
            font-size: 18px;
            font-family: Medium;
            color: #fc0000;
            span {
              font-size: 14px;
            }
          }
          .btn {
            width: 100px;
            height: 40px;
            border: 1px solid #2878ff;
            opacity: 1;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
            color: #2878ff;
            margin-left: 20px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .section {
    width: 1200px;
    padding: 20px 0 30px 0;
    .hen {
      display: flex;
      .selet1 {
        padding: 0 16px;
        font-size: 14px;
        margin-right: 7px;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 12px;
        color: #666;
      }
      .selet1:hover {
        background: #2878ff;
        color: #fff;
      }
      .seletActive1 {
        background: #2878ff;
        color: #fff;
      }
      .selet {
        width: 120px;
        padding: 0 16px;
        margin-top: 2px;
        font-size: 14px;
        margin-right: 7px;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 12px;
        color: #666;
      }
      .selet:hover {
        background: #2878ff;
        color: #fff;
      }
      .seletActive {
        background: #2878ff;
        color: #fff;
      }
    }
  }
}
</style>